import { useNavigate } from 'react-router-dom'

import logoSvg from '../../assets/images/LoginLogoImage.svg'
import ribeiroSoaresAdvPng from '../../assets/images/ribeiro-soares-adv.png'
import arrowIcon from '../../assets/images/ArrowIcon.svg'
import arrowOptionsIcon from '../../assets/images/arrow-options.svg'

import './styles.css'

export default function PrivacyPolicy() {
    const navigate = useNavigate()

    return (
        <div className="privacy-policy">
            <div className="a-back-action" onClick={() => navigate("/")}>
                <img src={arrowIcon} alt="" />
                <p>Voltar</p>
            </div>
            <div className="a-privacy-policy">
                <div className="header-privacy-policy">
                    <img src={logoSvg} alt="logo" />
                    <h1>Política de Privacidade</h1>
                </div>
                <div className="content">
                    <h2>1 - Informações gerais</h2>
                    <p>A presente Política de Privacidade contém informações a respeito do modo como tratamos, total ou parcialmente, de forma automatizada ou não, os dados pessoais dos usuários que acessam nosso aplicativo <span>Graça Digital</span> disponível na Play Store. Seu objetivo é esclarecer os interessados acerca dos tipos de dados que são coletados, dos motivos da coleta e da forma como o usuário poderá atualizar, gerenciar ou excluir estas informações.</p>
                    <p>Esta Política de Privacidade foi elaborada em conformidade com a Lei Federal n.12.965 de 23 de abril de 2014 (Marco Civil da Internet), com a Lei Federal n. 13.709, de 14 de agosto de 2018 (Lei de Proteção de Dados Pessoais) e com o Regulamento UE n. 2016/679 de 27 de abril de 2016 (Regulamento Geral Europeu de Proteção de Dados Pessoais - RGDP). Esta Política de Privacidade poderá ser atualizada em decorrência de eventual atualização normativa, razão pela qual se convida o usuário a consultar periodicamente esta seção.</p>

                    <h2>2 - Direitos do usuário</h2>
                    <h3>O aplicativose compromete a cumprir as normas previstas no RGPD, em respeito aos seguintes princípios:</h3>
                    <ul>
                        <li>Os dados pessoais do usuário serão processados de forma lícita, leal e transparente (licitude, lealdade e transparência);</li>
                        <li>Os dados pessoais do usuário serão coletados apenas para finalidades determinadas, explícitas e legítimas, não podendo ser tratados posteriormente de uma forma incompatível com essas finalidades (limitação das finalidades);</li>
                        <li>Os dados pessoais do usuário serão coletados de forma adequada, pertinente e limitada às necessidades do objetivo para os quais eles são processados (minimização dos dados);</li>
                        <li>Os dados pessoais do usuário serão exatos e atualizados sempre que necessário, de maneira que os dados inexatos sejam apagados ou retificados quando possível (exatidão);</li>
                        <li>Os dados pessoais do usuário serão conservados de uma forma que permita a identificação dos titulares dos dados apenas durante o período necessário para as finalidades para as quais são tratados (limitação da conservação);</li>
                        <li>Os dados pessoais do usuário serão tratados de forma segura, protegidos do tratamento não autorizado ou ilícito e contra a sua perda, destruição ou danificação acidental, adotando as medidas técnicas ou organizativas adequadas (integridade e confidencialidade).</li>
                    </ul>
                    <h3>O usuário do aplicativopossui os seguintes direitos, conferidos pela Lei de Proteção de Dados Pessoais e pelo RGPD:</h3>
                    <ul>
                        <li>Direito de confirmação e acesso: é o direito do usuário de obter do aplicativo a confirmação de que os dados pessoais que lhe digam respeito são ou não objeto de tratamento e, se for esse o caso, o direito de acessar os seus dados pessoais;</li>
                        <li>Direito de retificação: é o direito do usuário de obter do aplicativo, sem demora injustificada, a retificação dos dados pessoais inexatos que lhe digam respeito;</li>
                        <li>Direito à eliminação dos dados (direito ao esquecimento): é o direito do usuário de ter seus dados apagados do aplicativo;</li>
                        <li>Direito à limitação do tratamento dos dados: é o direito do usuário de limitar o tratamento de seus dados pessoais, podendo obtê-la quando contesta a exatidão dos dados, quando o tratamento for ilícito, quando o aplicativo não precisar mais dos dados para as finalidades propostas e quando tiver se oposto ao tratamento dos dados e em caso de tratamento de dados desnecessários;</li>
                        <li>Direito de oposição: é o direito do usuário de, a qualquer momento, se opor por motivos relacionados com a sua situação particular, ao tratamento dos dados pessoais que lhe digam respeito, podendo se opor ainda ao uso de seus dados pessoais para definição de perfil de marketing (profiling);</li>
                        <li>Direito de portabilidade dos dados: é o direito do usuário de receber os dados pessoais que lhe digam respeito e que tenha fornecido ao aplicativo, num formato estruturado, de uso corrente e de leitura automática, e o direito de transmitir esses dados a outro aplicativo;</li>
                        <li>Direito de não ser submetido a decisões automatizadas: é o direito do usuário de não ficar sujeito a nenhuma decisão tomada exclusivamente com base no tratamento automatizado, incluindo a definição de perfis (profiling), que produza efeitos na sua esfera jurídica ou que o afete significativamente de forma similar.</li>
                    </ul>
                    <h3>O usuário poderá exercer os seus direitos por meio de comunicação escrita enviada ao <span>aplicativo</span> com o assunto "RGDP-", especificando:</h3>
                    <ul>
                        <li>Nome completo ou razão social, número do CPF (Cadastro de Pessoas Físicas, da Receita Federal do Brasil) ou CNPJ (Cadastro Nacional de Pessoa Jurídica, da Receita Federal do Brasil) e endereço de e-mail do usuário e, se for o caso, do seu representante;</li>
                        <li>Direito que deseja exercer junto ao site;</li>
                        <li>Data do pedido e assinatura do usuário;</li>
                        <li>Todo documento que possa demonstrar ou justificar o exercício de seu direito.</li>
                    </ul>
                    <p>O pedido deverá ser enviado ao e-mail: gracadigital@gracamultimidia.com.br, ou por correio, ao seguinte endereço: <span>Graça Multimidia Sociedade Ltda - Graça Digital</span>, Estrada do Guerengue, 25 - Taquara - Rio de Janeiro - RJ - CEP: 22713-003.</p>
                    <p>O usuário será informado em caso de retificação ou eliminação dos seus dados.</p>

                    <h2>3 - Dever de não fornecer dados de terceiros</h2>
                    <p>Durante a utilização do site, a fim de resguardar e de proteger os direitos de terceiros, o usuário do <span>aplicativo</span> deverá fornecer somente seus dados pessoais, e não os de terceiros.</p>

                    <h2>4 - Informações coletadas</h2>
                    <p>A coleta de dados dos usuários se dará em conformidade com o disposto nesta Política de Privacidade e dependerá do consentimento do usuário, sendo este dispensável somente nas hipóteses previstas no art. 11, inciso II, da Lei de Proteção de Dados Pessoais.</p>

                    <h2>4.1 - Tipos de dados coletados</h2>

                    <h2>4.1.1 - Dados de identificação do usuário para realização de cadastro</h2>
                    <h3>A utilização, pelo usuário, de determinadas funcionalidades do aplicativo dependerá de cadastro, sendo que, nestes casos, os seguintes dados do usuário serão coletados e armazenados:</h3>
                    <ul>
                        <li>Nome</li>
                        <li>Endereço de e-mail</li>
                    </ul>

                    <h2>4.1.2 - Dados informados no formulário de contato</h2>
                    <p>Os dados eventualmente informados pelo usuário que utilizar o formulário de contatodisponibilizado no aplicativo, incluindo o teor da mensagem enviada, serão coletadose armazenados.</p>

                    <h2>4.1.3 - Dados relacionados à execução de contratos firmados com o usuário</h2>
                    <p>Para a execução de contrato de compra e venda ou de prestação de serviços eventualmente firmado entre o aplicativo e o usuário, poderão ser coletados e armazenados outros dados relacionados ou necessários a sua execução, incluindo o teor de eventuais comunicações tidas com o usuário.</p>

                    <h2>4.1.4 - Registros de acesso</h2>
                    <p>Em atendimento às disposições do art. 15, caput e parágrafos, da Lei Federal n.12.965/2014 (Marco Civil da Internet), os registros de acesso do usuário serão coletados e armazenados por, pelo menos, seis meses.</p>

                    <h2>4.1.5 - Dados sensíveis</h2>
                    <h3>O Aplicativo poderá coletar os seguintes dados sensíveis dos usuários:</h3>
                    <ul>
                        <li>Nome</li>
                        <li>Endereço de e-mail</li>
                    </ul>

                    <h2>4.1.6 - Coleta de dados não previstos expressamente</h2>
                    <p>Eventualmente, outros tipos de dados não previstos expressamente nesta Política de Privacidade poderão ser coletados, desde que sejam fornecidos com o consentimento do usuário, ou, ainda, que a coleta seja permitida ou imposta por lei.</p>

                    <h2>4.2 - Fundamento jurídico para o tratamento dos dados pessoais</h2>
                    <p>Ao utilizar os serviços do aplicativo, o usuário está consentindo com a presente Política de Privacidade.</p>
                    <p>O usuário tem o direito de retirar seu consentimento a qualquer momento, não comprometendo a licitude do tratamento de seus dados pessoais antes da retirada. A retirada do consentimento poderá ser feita pelo e-mail: gracadigital@gracamultimidia.com.br, ou por correio enviado ao seguinte endereço: Estrada do Guerengue, 25 - Taquara - Rio de Janeiro - RJ - CEP: 22713-003.</p>
                    <p>O consentimento dos relativamente ou absolutamente incapazes, especialmente de crianças menores de 16 (dezesseis) anos, apenas poderá ser feito, respectivamente, se devidamente assistidos ou representados.</p>
                    <p>Poderão ainda ser coletados dados pessoais necessários para a execução e cumprimento dos serviços contratados pelo usuário no aplicativo.</p>
                    <h3>O tratamento de dados pessoais sem o consentimento do usuário apenas será realizado em razão de interesse legítimo ou para as hipóteses previstas em lei, ou seja, dentre outras, as seguintes:</h3>
                    <ul>
                        <li>para o cumprimento de obrigação legal ou regulatória pelo controlador;</li>
                        <li>para a realização de estudos por órgão de pesquisa, garantida, sempre que possível, a anonimização dos dados pessoais;</li>
                        <li>quando necessário para a execução de contrato ou de procedimentos preliminares relacionados a contrato do qual seja parte o usuário, a pedido do titular dos dados;</li>
                        <li>para a proteção da vida ou da incolumidade física do titular dos dados ou de terceiro;</li>
                        <li>para a tutela da saúde, em procedimento realizado por profissionais da área da saúde ou por entidades sanitárias;</li>
                        <li>quando necessário para atender aos interesses legítimos do controlador ou de terceiro, exceto no caso de prevalecerem direitos e liberdades fundamentais do titular dos dados que exijam a proteção dos dados pessoais;</li>
                        <li>para a proteção do crédito, inclusive quanto ao disposto na legislação pertinente.</li>
                    </ul>

                    <h2>4.3 - Finalidades do tratamento dos dados pessoais</h2>
                    <p>Os dados pessoais do usuário coletados pelo aplicativo têm por finalidade facilitar, agilizar e cumprir os compromissos estabelecidos com o usuário e a fazer cumprir assolicitações realizadas por meio do preenchimento de formulários.</p>
                    <p>Os dados pessoais poderão ser utilizados também com uma finalidade comercial, para personalizar o conteúdo oferecido ao usuário, bem como para dar subsídio ao aplicativo para a melhora da qualidade e funcionamento de seus serviços.</p>
                    <p>O aplicativo recolhe os dados do usuário para que seja realizada definição de perfis (profiling), ou seja, tratamento automatizado de dados pessoais que consista em utilizar estes dados para avaliar certos aspectos pessoais do usuário, principalmente para analisar ou prever características relacionadas ao seu desempenho profissional, a sua situação econômica, saúde, preferências pessoais, interesses, fiabilidade, comportamento, localização ou deslocamento.</p>
                    <p>Os dados de cadastro serão utilizados para permitir o acesso do usuário a determinado conteúdo do aplicativo, exclusivos para usuários cadastrados.</p>
                    <p>A coleta de dados relacionados ou necessários à execução de um contrato de compra e venda ou de prestação de serviços eventualmente firmado com o usuário terá a finalidade de conferir às partes segurança jurídica, além de facilitar e viabilizar a conclusão do negócio.</p>
                    <p>O tratamento de dados pessoais para finalidades não previstas nesta Política de Privacidade somente ocorrerá mediante comunicação prévia ao usuário, sendo que, em qualquer caso, os direitos e obrigações aqui previstos permanecerão aplicáveis.</p>

                    <h2>4.4 - Prazo de conservação dos dados pessoais</h2>
                    <p>Os dados pessoais do usuário serão conservados pelo prazo máximo de: 2 anos, exceto se o usuário solicitar a sua supressão antes do final deste prazo.</p>
                    <h3>Os dados pessoais dos usuários apenas poderão ser conservados após o término de seu tratamento nas seguintes hipóteses:</h3>
                    <ul>
                        <li>para o cumprimento de obrigação legal ou regulatória pelo controlador;</li>
                        <li>para estudo por órgão de pesquisa, garantida, sempre que possível, a anonimização dos dados pessoais;</li>
                        <li>para a transferência a terceiro, desde que respeitados os requisitos de tratamento de dados dispostos na legislação;</li>
                        <li>para uso exclusivo do controlador, vedado seu acesso por terceiro, e desde que anonimizados os dados.</li>
                    </ul>

                    <h2>4.5 - Destinatários e transferência dos dados pessoais</h2>
                    <h3>Os dados pessoais do usuário poderão ser compartilhados com as seguintes pessoas ou empresa do Grupo:</h3>
                    <p><span>Graça Multimidia Sociedade Ltda - Graça Digital</span> - CNPJ/MF: 02.351.675/0001-00</p>
                    <p>A transferência apenas poderá ser feita para outro país caso o país ou território em questão ou a organização internacional em causa assegurem um nível de proteção adequado dos dados do usuário.</p>
                    <p>Caso não haja nível de proteção adequado, o aplicativo se compromete a garantir a proteção dos seus dados de acordo com as regras mais rigorosas, por meio de cláusulas contratuais específicas para determinada transferência, cláusulas-padrão contratuais, normas corporativas globais ou selos, certificados e códigos de conduta regularmente emitidos.</p>

                    <h2>5 - Do tratamento dos dados pessoais</h2>

                    <h2>5.1 - Do responsável pelo tratamento dos dados (data controller)</h2>
                    <p>O controlador, responsável pelo tratamento dos dados pessoais do usuário, é a pessoa física ou jurídica, a autoridade pública, a agência ou outro organismo que, individualmente ou em conjunto com outras, determina as finalidades e os meios de tratamento de dados pessoais.</p>
                    <p>Neste <span>aplicativo</span>, o responsável pelo tratamento dos dados pessoais coletados é <span>Graça Digital CNPJ: 02.351.675/0001-00</span>, representada por <span>Graça Multimidia Sociedade Ltda</span>, que poderá ser contactado pelo e-mail: gracadigital@gracamultimidia.com.br ou no endereço: Estrada do Guerengue, 25 - Taquara - Rio de Janeiro - RJ - CEP: 22713-003.</p>
                    <p>O responsável pelo tratamento dos dados se encarregará diretamente do tratamento dos dados pessoais do usuário.</p>

                    <h2>5.2 - Do encarregado de proteção de dados (data protection officer)</h2>
                    <p>O encarregado de proteção de dados (data protection officer) é o profissional encarregado de informar, aconselhar e controlar o responsável pelo tratamento dos dados, bem como os trabalhadores que tratem os dados, a respeito das obrigações do aplicativonos termos do RGDP, da Lei de Proteção de Dados Pessoais e de outras disposições de proteção de dados presentes na legislação nacional e internacional, em cooperação com a autoridade de controle competente.</p>
                    <p>Neste aplicativoo encarregado de proteção de dados (data protection officer) é <span>TI Pamafa</span>, que poderá ser contactado pelo e-mail: desenvolvimento@pamafa.com.br.</p>

                    <h2>6 - Segurança no tratamento dos dados pessoais do usuário</h2>
                    <p>O aplicativo se compromete a aplicar as medidas técnicas e organizativas aptas a proteger os dados pessoais de acessos não autorizados e de situações de destruição, perda, alteração, comunicação ou difusão de tais dados.</p>
                    <p>Para a garantia da segurança, serão adotadas soluções que levem em consideração: as técnicas adequadas; os custos de aplicação; a natureza, o âmbito, o contexto e as finalidades do tratamento; e os riscos para os direitos e liberdades do usuário.</p>
                    <p>O aplicativoutiliza certificado SSL (Secure Socket Layer) que garante que os dados pessoais se transmitam de forma segura e confidencial, de maneira que a transmissão dos dados entre o servidor e o usuário, e em retroalimentação, ocorra de maneira totalmente cifrada ou encriptada.</p>
                    <p>No entanto, o aplicativo se exime de responsabilidade por culpa exclusiva de terceiro, como em caso de ataque de hackers ou crackers, ou culpa exclusiva do usuário, como no caso em que ele mesmo transfere seus dados a terceiro. O aplicativose compromete, ainda, a comunicar o usuário em prazo adequado caso ocorra algum tipo de violação da segurança de seus dados pessoais que possa lhe causar um alto risco para seus direitos e liberdades pessoais.</p>
                    <p>A violação de dados pessoais é uma violação de segurança que provoque, de modo acidental ou ilícito, a destruição, a perda, a alteração, a divulgação ou o acesso não autorizado a dados pessoais transmitidos, conservados ou sujeitos a qualquer outro tipo de tratamento.</p>
                    <p>Por fim, o aplicativo se compromete a tratar os dados pessoais do usuário com confidencialidade, dentro dos limites legais.</p>

                    <h2>7 - Reclamação a uma autoridade de controle</h2>
                    <p>Sem prejuízo de qualquer outra via de recurso administrativo ou judicial, todos os titulares de dados têm direito a apresentar reclamação a uma autoridade de controle. A reclamação poderá ser feita à autoridade da sede do aplicativo, do país de residência habitual do usuário, do seu local de trabalho ou do local onde foi alegadamente praticada a infração.</p>

                    <h2>8 - Das alterações</h2>
                    <p>A presente versão desta Política de Privacidade foi atualizada em 01/02/2023.</p>
                    <p>O editor se reserva o direito de modificar, a qualquer momento o aplicativo as presentes normas, especialmente para adaptá-las às evoluções do aplicativo Graça Digital, seja pela disponibilização de novas funcionalidades, seja pela supressão ou modificação daquelas já existentes.</p>
                    <p>O usuário será explicitamente notificado em caso de alteração desta política.</p>
                    <p>Ao utilizar o serviço após eventuais modificações, o usuário demonstra sua concordância com as novas normas. Caso discorde de alguma das modificações, deverá pedir, imediatamente, o cancelamento de sua conta e apresentar a sua ressalva ao serviço de atendimento, se assim o desejar.</p>

                    <h2>9 - Do Direito aplicável e do foro</h2>
                    <p>Para a solução das controvérsias decorrentes do presente instrumento, será aplicadointegralmente o Direito brasileiro. Os eventuais litígios deverão ser apresentados no foro Central da comarca da Capital do Estado do Rio de Janeiro em que se encontra a sede do editor do <span>aplicativo</span>.</p>
                </div>
            </div>
            <footer>
                <div className="a-footer">
                    <div className="footer-brands">
                        <img src={logoSvg} alt="Graça Digital logo" />
                        <img src={ribeiroSoaresAdvPng} alt="" />
                    </div>
                    <p>Copyright © {new Date().getFullYear()} Graça Multimidia. Todos os direitos reservados.</p>
                </div>
                <div className="nav-top" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                    <img src={arrowOptionsIcon} alt="Navegação para o topo" />
                </div>
            </footer>
        </div>
    )
}