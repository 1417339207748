import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import BannerLogin from '../../assets/images/BannerLogin.svg'
import LoginLogoImage from '../../assets/images/LoginLogoImage.svg'
import btnGoogle from '../../assets/images/btnGoogle.svg'

import './styles.css'

//components
import { showLoadIndicator } from '../../components/LoadIndicator'
import Input from '../../components/Input'

//controllers
import Auth from '../../controllers/ControllerAuth'

export const Login = () =>
{
    const [email, setEmail]       = useState(""),
          [password, setPassword] = useState(""),
          navigate                = useNavigate()

    const loginAction = e =>
    {
        e.preventDefault()

        showLoadIndicator(async () =>
        {
            if(await Auth.signInWithEmailAndPassword(email, password))
                Auth.signInRedirect(navigate)
        })
    }

    const signInWithGoogle = () => showLoadIndicator(async () => {
        const signIn = await Auth.signInWithGoogle()

        if(signIn.user)
        {
            showLoadIndicator(async () =>
            {
                await Auth.isLoadUser()
        
                Auth.signInRedirect(navigate)
            })
        }
    })

    return (
        <div className="a-page-login">
            <div className="linha">
                <div className="coluna-A">
                    <img className="LoginPageImage" src={BannerLogin} alt="" />
                </div>
                <div className="coluna-B">
                    <div id="login">
                        <form className="FormLogin" onSubmit={loginAction}>
                            <img className="LoginLogoImage" src={LoginLogoImage} alt="" />
                            <Input type="email" placeholder={"E-mail"} value={email} onChange={e => setEmail(e.target.value)} style={{ marginBottom: "24px" }} />
                            <Input type="password" placeholder={"Senha"} value={password} onChange={e => setPassword(e.target.value)}  style={{ marginBottom: "24px" }} />
                            <button className="btn btn-enter-login">Entrar</button>
                            <div className='RecuperarSenha'>
                                <p>Esqueceu sua senha?<span onClick={() => navigate('/forgotpassword')} className="blueColor">Recuperar</span></p>
                            </div>
                            <fieldset className="LineSeparator">
                                <legend>OU</legend>
                            </fieldset>
                            <div className="btn btn-google" onClick={signInWithGoogle}>
                                <img className="icongoogle" src={btnGoogle} alt="" />
                                <p>Entrar com o Google</p>
                            </div>
                            <div className='Registrar'>
                                <p>Não possui uma conta?<span onClick={() => navigate('/register')} className="blueColor">Registrar</span></p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="background-black"></div>
        </div>
    )
}

export default Login; 
