import React, { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

//components
import { showLoadIndicator } from '../../components/LoadIndicator'

//controllers
import Auth from '../../controllers/ControllerAuth'
import Alert from '../../controllers/ControllerAlert'

export default function Rescue() {
    const { isbn, code } = useParams(),
          navigate       = useNavigate()

    useEffect(() => {
        showLoadIndicator(async () => {
            const rescueEbooksList = sessionStorage.getItem("rescueEbooks"),
                  data = { isbn, code: code.toUpperCase() }
    
            sessionStorage.setItem("rescueEbooks", JSON.stringify(rescueEbooksList ? [ ...JSON.parse(rescueEbooksList), data ] : [data]) )

            if(Auth.user) {
                navigate("/")              
                console.log("CAIU 0")
            }
            else {
                Alert.success("Atenção", "Para efetuar o resgate do livro, efetue o login ou se cadastre!")

                navigate("/login")
            }
        })
    }, [code, isbn, navigate])

    return <></>
}
