import CheckIcon from '../../assets/images/CheckIcon.svg'

import './styles.css'

export const RulesPassword = ({ value }) => {
  // Ao menos uma letra minúscula
  const atLeastOneLowercaseLetter = () => {
    const regex = /[a-z]/
    return regex.test(value)
  }

  // Ao menos uma letra maiúscula
  const atLeastOneUppercaseLetter = () => {
    const regex = /[A-Z]/
    return regex.test(value)
  }

  // Ao menos um caracter especial
  const atLeastOneSpecialCharacter = () => {
    const regex = /[^a-zA-Z0-9]/
    return regex.test(value)
  }

  // Ao menos um número
  const atLeastOneNumber = () => {
    const regex = /[0-9]/
    return regex.test(value)
  }

  // Ter no mínimo 8 caracteres
  const atLeast8Characters = () => {
    const regex = /.{8,}/
    return regex.test(value)
  }

  const handleIcon = (rule) => {
    return (
      <div className={`rulePassword__checkbox ${rule() ? 'checked' : null}`}>
        <img src={CheckIcon} alt="" />
      </div>
    )
  }

  return (
    <div className="rulePassword__content">
      <p className="title">Sua senha deve ter:</p>
      <div>
        <div className="item">
          {handleIcon(atLeast8Characters)}
          <span>Ter no mínimo ter 8 caracteres</span>
        </div>
        <div className="item">
          {handleIcon(atLeastOneLowercaseLetter)}
          <span>Ao menos uma letra minúscula</span>
        </div>
        <div className="item">
          {handleIcon(atLeastOneUppercaseLetter)}
          <span>Ao menos uma letra maiúscula</span>
        </div>
        <div className="item">
          {handleIcon(atLeastOneSpecialCharacter)}
          <span>Ao menos um caractere especial</span>
        </div>
        <div className="item">
          {handleIcon(atLeastOneNumber)}
          <span>Ao menos um número</span>
        </div>
      </div>
    </div>
  )
}
