import { useEffect } from 'react'

import logoSvg from '../../assets/images/LoginLogoImage.svg'
import googlePlayBadgePng from '../../assets/images/google-play-badge.png'
import appStoreBadgePng from '../../assets/images/app-store-badge.png'

import { verifyRescueEbooks } from '../../utils/verifyRescueEbooks'

import './styles.css'

export default function DownloadApp() {
    
    useEffect(() => {
        verifyRescueEbooks()
    }, [])
    return (
        <div className="a-page-downloadApp">
            <div className="a-content">
                <div className="content">
                    <div className="logo">
                        <img src={logoSvg} alt="logo" />
                    </div>
                    <div className="title">
                        <h1>BAIXE AGORA</h1>
                        <h3>SEU APP</h3>
                    </div>
                    <div className="description">
                        <p>Leia seus livros digitais de onde quiser e como quiser. Com o aplicativo você pode ler mesmo offline. Baixe agora o seu aplicativo e resgate seu livro.</p>
                    </div>
                </div>
            </div>
            <div className="a-footer">
                <div className="content">
                    <div className="title">
                        <h4>Download App</h4>
                    </div>
                    <div className="shop-list">
                        <div className="google-play">
                            <a href="https://play.google.com/store/apps/details?id=com.deviigd.gracadigital" target="_blank" rel="noreferrer">
                                <img src={googlePlayBadgePng} alt="download google play" />
                            </a>
                        </div>
                        <div className="app-store">
                            <a href="https://apps.apple.com/br/app/gra%C3%A7a-digital/id1668867796?l=en" target="_blank" rel="noreferrer">
                                <img src={appStoreBadgePng} alt="download app store" />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="footer-background"></div>
            </div>
        </div>
    )
}