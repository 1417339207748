import { AppRoutes } from "./routes"
import './reset.css'
import './index.css'

//controllers
import Firebase from './controllers/ControllerFirebase'

//triggers
import TriggerAuth from './triggers/triggerAuth'

//components
import LoadIndicator from './components/LoadIndicator'
import AlertIndicator from './components/AlertIndicator'
import IsLoadingUserData from './components/IsLoadingUserData'

export const App = () =>
{
    Firebase.initializeApp()
    TriggerAuth()
    
    return (
        <>
            <IsLoadingUserData>
                <AppRoutes />
            </IsLoadingUserData>
            <LoadIndicator />
            <AlertIndicator />
        </>
    )
}