import axios from 'axios'

export class BrasilApiService {
    #url = "https://brasilapi.com.br"

    async getIsbn(isbn) {
        try {
            if(!isbn || isbn.length < 13)
                throw new Error("ISBN inválido")

            const options = {
                method: "GET",
                url: `${this.#url}/api/isbn/v1/${isbn}`,
                headers: {
                    'Content-Type': 'application/json'
                }
            }

            const { data } = await axios(options)

            return data
        } catch(error) {
            console.error(error)

            return false
        }
    }
}