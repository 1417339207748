import { v4 as uuidv4 } from 'uuid'
import { alert as AlertIndicator } from '../components/AlertIndicator'
import errorsList from '../config/errors.json'

export default class Alert
{
    static #alertList = new Map()
    /**
     * Exibe uma mensagem de erro.
     * 
     * @example
     * ```js
     * Alert.error({ message: "Ocorreu um erro" }, () =>
     * {
     *     //Clicou Ok
     * }, () =>
     * {
     *     //Clicou fora do log de erro
     * })
     * ```
     * 
     * @param {Error | { code: string, title: string, message: string }} error - Objeto de erro.
     * @param {function} onPress   - Função "callback" indicativo do clique no "ok"
     * @param {function} onDismiss - Função "callback" indicativo do clique fora do log
     * @param {string} id          - Identificação destinado para sistema interno, ignore-o na hora de usar o metodo.
     * 
     * @returns
     */

    static error(error, onPress, onDismiss, id) {
        let waitRow = false

        if(!id)
            [id, waitRow] = this.#insertAlertFromList(3, arguments, "error")

        if(waitRow)
            return

        if(!error)
            throw new Error("O parâmetro 'error' é obrigatório")
        else if(typeof error !== "object" || Array.isArray(error))
            throw new Error("O parâmetro 'error' precisa ser um objeto")

        let code,
            title,
            message

        if(error.code)
            code = error.code
        else {
            title = error.title
            message = error.message
        }

        if(code) {
            if(!errorsList[code])
                throw new Error(`Código inválido. Código de erro não cadastrado. Código: ${code}\n${error.message}`)
            else
                message = errorsList[code]
        }
        
        AlertIndicator(
            title   || "Erro",
            message || "Ocorreu um erro",
            [{ text: "Ok", onPress: async () => {
                if(code === "auth/session-not-found")
                    window.location.reload(false)
                else
                    await onPress?.()
                this.#removeAlertFromList(id)
            } }], {
                cancelable: true,
                cancelAction: async () => {
                    await onDismiss?.()
                    this.#removeAlertFromList(id)
                }
            }
        )
    }

    /**
     * Exibe uma mensagem de sucesso.
     * 
     * @example
     * ```js
     * Alert.success("Salvo com sucesso", "Seus dados foram salvos com sucesso", () =>
     * {
     *     //Clicou Ok
     * })
     * ```
     * 
     * @param {string} title     - Título da mensagem de sucesso.
     * @param {string} message   - Mensagem de sucesso.
     * @param {function} onPress - Função "callback" indicativo do clique no "ok".
     * @param {string} id        - Identificação destinado para sistema interno, ignore-o na hora de usar o metodo.
     * 
     * @returns
     */

    static success(title, message, onPress, id) {
        let waitRow = false

        if(!id)
            [id, waitRow] = this.#insertAlertFromList(3, arguments, "success")

        if(waitRow)
            return

        AlertIndicator(title, message, [{ text: "Continuar", onPress: async () => {
            await onPress?.()
            this.#removeAlertFromList(id)
        } }])
    }

    /**
     * Exibe uma mensagem de alerta.
     * 
     * @example
     * ```js
     * Alert.alert("Aviso", "Seus dados serão perdidos, tem certeza que deseja sair?", () =>
     * {
     *     //Clicou Ok
     * }, () =>
     * {
     *     //Clicou cancelar
     * })
     * ```
     * 
     * @param {string} title           - Título da mensagem de sucesso.
     * @param {string} message         - Mensagem de sucesso.
     * @param {function} onPressOk     - Função "callback" indicativo do clique no "ok".
     * @param {function} onPressCancel - Função "callback" indicativo do clique no "cancelar".
     * @param {string} id              - Identificação destinado para sistema interno, ignore-o na hora de usar o metodo.
     * 
     * @returns
     */

    static alert(title, message, onPressOk, onPressCancel, id) {
        let waitRow = false

        if(!id)
            [id, waitRow] = this.#insertAlertFromList(4, arguments, "alert")

        if(waitRow)
            return

        const cancelEvent = async () => {
            await onPressCancel?.()
            this.#removeAlertFromList(id)
        }

        AlertIndicator(title, message, [{ text: "Continuar", onPress: async () => {
            await onPressOk?.()
            this.#removeAlertFromList(id)
        } }, {
            text: "Cancelar",
            onPress: cancelEvent
        }], {
            cancelable: true,
            cancelEvent
        })
    }

    /**
     * Adiciona um alerta na fila de espera, caso já tenha um alerta sendo exibido.
     * 
     * @param {array} params - Lista de parâmetro que são passados ao no metodo de alerta.
     * 
     * @returns
     */

    static #insertAlertFromList(numberOfParams, params, method) {
        const id = uuidv4()

        this.#alertList.set(id, {
            value: Object.assign(new Array(numberOfParams), params),
            method
        })

        return [id, this.#alertList.size > 1]
    }
    
    /**
     * Remove o alerta da lista de espera.
     * 
     * @param {OutputBuffer} id 
     * @param {string} method 
     */

    static #removeAlertFromList(id) {
        if(id) {
            this.#alertList.delete(id)
    
            if(this.#alertList.size > 0) {
                const [key, { method, value }] = this.#alertList?.entries?.()?.next?.()?.value

                setTimeout(() => 
                    Alert[method]?.(...value, key)
                , 500)
            }
        }
    }
}