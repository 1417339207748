export const validateEmail = (email) => {
  const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  return regex.test(email)
}

/**
 * A senha deve conter:
 * - Ao menos uma letra minúscula.
 * - Ao menos uma letra maiúscula
 * - Ao menos um caractere especial
 * - Ao menos um número
 */
export const validatePassword = (password) => {
  const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^\w\s]).{8,}$/ 
  
  return regex.test(password)
}