import { useState } from 'react'

import mailIcon from '../../assets/images/MailIcon.svg'
import passwordIcon from '../../assets/images/PasswordIcon.svg'
import eyePassword from '../../assets/images/eyePassword.svg'
import profileIcon from '../../assets/images/ProfileIcon.svg'
import searchIconSvg from '../../assets/images/search-icon.svg'

import './styles.css'

const Input = ({ type, placeholder, value, onBlur, onChange, style, showIcon, disabled }) => {
    const [showPassword, setShowPassword] = useState(false)

    const buttonType = {
        "email": {
            icon: mailIcon,
            type: "email",
        },
        "password": {
            icon: passwordIcon,
            type: "password"
        },
        "username": {
            icon: profileIcon,
            type: "text"
        },
        "search": {
            icon: searchIconSvg,
            type: "text"
        }
    }

    showIcon = showIcon ?? true

    return (
        <div className="input-component" style={{...style, ...{ cursor: disabled ? "no-drop" : null }}}>
            {
                showIcon &&
                    <div className="input-icon">
                        <img src={buttonType?.[type]?.icon} alt="" />
                    </div>
            }
            <input
                type={showPassword ? "text" : buttonType?.[type]?.type}
                placeholder={placeholder}
                value={value}
                disabled={disabled}
                onChange={onChange}
                onBlur={onBlur}
                style={{ width: type === "password" ? `calc(100% - ${showIcon ? 90 : 20}px)` : `calc(100% - ${showIcon ? 50 : 20}px)` }}
            />
            {
                type === "password" &&
                    <img className="input-eye" src={eyePassword} alt="" onClick={() => setShowPassword(!showPassword)} />
            }
        </div>
    )
}

export default Input