import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'

//components
import ProtectedRoute from '../components/ProtectedRoute'

//pages
import Login from '../pages/Login'
import Register from '../pages/Register'
import Validation from '../pages/Validation'
import ForgotPassword from '../pages/ForgotPassword'
import InitialPage from '../pages/InitialPage'
import ChangePassword from '../pages/ChangePassword'
import EbookViewer from '../pages/EbookViewer'
import Rescue from '../pages/Rescue'
import PrivacyPolicy from '../pages/PrivacyPolicy'

export const AppRoutes = () =>
{
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<ProtectedRoute Element={InitialPage} loginRequired />} />
                <Route path="/validation" element={<ProtectedRoute Element={Validation} loginRequired />} />
                <Route path="/changepassword" element={<ProtectedRoute Element={ChangePassword} loginRequired />} />
                <Route path="/ebookviewer/:EBookId" element={<ProtectedRoute Element={EbookViewer} loginRequired />} />
                <Route path="/register" element={<ProtectedRoute Element={Register} logoutRequired />} />
                <Route path="/forgotpassword" element={<ProtectedRoute Element={ForgotPassword} logoutRequired />} />
                <Route path="/login" element={<ProtectedRoute Element={Login} logoutRequired />} />
                <Route path="/rescue/:isbn/:code" element={<ProtectedRoute Element={Rescue} />} />
                <Route path="/privacypolicy" element={<ProtectedRoute Element={PrivacyPolicy} />} />
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </BrowserRouter>
    )
}