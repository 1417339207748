import Firebase from './ControllerFirebase'
import Alert from './ControllerAlert'
import Auth from './ControllerAuth'

export default class EBook
{
    static async isEBookIdValid(eBookId)
    {
        try
        {
            if(!eBookId)
                throw new Error("Variable 'eBookId' cannot be null")

            const eBooks = await Firebase.firestore().collection("ebooks").where("isbn", "==", parseInt(eBookId)).get()
            
            return !!eBooks.size
        }
        catch(error)
        {
            Alert.error(error)

            return false
        }
    }

    static async userHasEBookId(eBookId)
    {
        try
        {
            if(!eBookId)
                throw new Error("Variable 'eBookId' cannot be null")

            const eBooks = await Firebase.firestore().collection("ebooks").where("isbn", "==", parseInt(eBookId)).get()

            if(eBooks.size > 0)
            {                
                const eBook      = eBooks.docs[0],
                      userEBooks = await Auth.user.ref.collection("ebooks").where("eBook", "==", Firebase.firestore().doc(`ebooks/${eBook.id}`)).get()

                if(userEBooks.size > 0)
                {
                    const key = await Firebase.firestore().doc(userEBooks.docs[0].data().key.path).get()

                    return (key.exists && key.data().users.some(userListRef => userListRef.id === Auth.user.uid))
                }
                else
                {
                    const userInEBooks = await eBook.ref.collection("keys").where("users", "array-contains", Auth.user.ref).get()

                    if(userInEBooks.size > 0)
                    {
                        for(const userInEBook of userInEBooks.docs)
                        {
                            const listUsers   = userInEBook.data().users,
                                  listUsersId = listUsers.map(userListRef => userListRef.id)
                            
                            if(listUsersId.indexOf(Auth.user.uid) >= 0)
                                listUsers.splice(listUsersId.indexOf(Auth.user.uid), 1)

                            await userInEBook.ref.update({ users: listUsers })
                        }
                    }
                }
            }

            return false
        }
        catch(error)
        {
            Alert.error(error)
            
            return false
        }
    }

    static async validateKeyEBook(eBookId, keyCode)
    {
        try
        {
            if(!keyCode)
                throw { code: "generic/fill-in-all-fields" }

            const eBooks = await Firebase.firestore().collection("ebooks").where("isbn", "==", parseInt(eBookId)).get()
            
            if(eBooks.size <= 0)
                throw { message: "Livro inválido" }

            const keys = await eBooks.docs[0].ref.collection("keys").where("code", "==", keyCode).get()

            if(keys.size <= 0)
                throw { message: "Código de confirmação inválido" }

            const key     = keys.docs[0],
                  keyData = key.data()

            if(!keyData.users || typeof keyData.users !== "object")
                keyData.users = []

            if(keyData.users.some(userListRef => userListRef.id === Auth.user.uid))
                throw { message: "Você já resgatou este código nesta conta" }

            if(keyData.users.length >= 1)
                throw { message: "Este código já foi utilizado" }
                // throw { message: "Este código atingiu o seu limite de utilização" }

            keyData.users.push(Auth.user.ref)

            await key.ref.update({ users: keyData.users })
            await Auth.user.ref.collection("ebooks").add(
            {
                key: key.ref,
                eBook: eBooks.docs[0].ref,
                time: Firebase.firestore.FieldValue.serverTimestamp()
            })

            return true
        }
        catch(error)
        {
            Alert.error(error)

            return false
        }
    }

    static async getMyEBooks()
    {
        try
        {
            let eBookList = []
            
            if(Auth.user.permission >= 1)
            {
                const eBooks = await Firebase.firestore().collection("ebooks").orderBy("publish", "desc").get()

                if(eBooks?.size > 0)
                {
                    await Promise.all(eBooks.docs.map(async eBook => {
                        const eBookData                = eBook.data(),
                              epubRef                  = Firebase.storage().ref(`ebooks/${eBookData.isbn}/ebook.epub`),
                              frontCoverRef            = Firebase.storage().ref(`ebooks/${eBookData.isbn}/front-cover`),
                              [epubUrl, frontCoverUrl] = await Promise.all([epubRef.getDownloadURL(), frontCoverRef.getDownloadURL()])

                        eBookList.push(
                        {
                            id: eBook.id,
                            title: eBookData.title,
                            author: eBookData.author,
                            publish: eBookData.publish,
                            isbn: eBookData.isbn,
                            frontCoverUrl,
                            epubUrl
                        })
                    }))
                }
            }
            else
            {
                const myEBooks = await Auth.user.ref.collection("ebooks").orderBy("time", "desc").get()

                if(myEBooks?.size > 0)
                {
                    await Promise.all(myEBooks.docs.map(async myEBook => {
                        const myEBookData  = myEBook.data(),
                              [eBook, key] = await Promise.all(
                        [
                            Firebase.firestore().doc(myEBookData.eBook.path).get(),
                            Firebase.firestore().doc(myEBookData.key.path).get()
                        ])

                        if(eBook.exists && key.exists && key.data().users.some(userListRef => userListRef.id === Auth.user.uid))
                        {
                            const eBookData                = eBook.data(),
                                  epubRef                  = Firebase.storage().ref(`ebooks/${eBookData.isbn}/ebook.epub`),
                                  frontCoverRef            = Firebase.storage().ref(`ebooks/${eBookData.isbn}/front-cover`),
                                  [epubUrl, frontCoverUrl] = await Promise.all([epubRef.getDownloadURL(), frontCoverRef.getDownloadURL()])

                            eBookList.push(
                            {
                                id: eBook.id,
                                title: eBookData.title,
                                author: eBookData.author,
                                publish: eBookData.publish,
                                isbn: eBookData.isbn,
                                rescueTime: myEBook.data().time,
                                frontCoverUrl,
                                epubUrl
                            })
                        }
                    }))
                }
            }

            const storageDataList = await Promise.all(eBookList.map(data => window?.localStorage?.getItem(data.id.toString())))

            eBookList = eBookList.map((data, index) =>
            {
                const storageData = JSON.parse(storageDataList[index])

                if(storageData)
                    return {...data, storage: storageData}

                return data
            })

            return eBookList
        }
        catch(error)
        {
            Alert.error(error)

            return []
        }
    }

    static async getFileUrlEBookById(eBookId)
    {
        try
        {
            const eBooks = await Firebase.firestore().collection("ebooks").doc(eBookId).get()

            if(eBooks.exists)
            {
                const keys = await eBooks.ref.collection("keys").where("users", "array-contains", Auth.user.ref).get()
                
                if(keys.size > 0 || Auth?.user?.permission >= 1)
                {
                    const isbn = eBooks.data().isbn

                    if(isbn)
                        return await Firebase.storage().ref(`ebooks/${isbn}/ebook.epub`).getDownloadURL()
                }
            }

            return false
        }
        catch(error)
        {
            Alert.error(error)

            return false
        }
    }

    static async getEbookDataByIsbn(isbn) {
        try {
            if(!isbn)
                throw new Error("Isbn is required")
                
            const eBooks = await Firebase.firestore().collection("ebooks").where("isbn", "==", parseInt(isbn)).get()

            if(eBooks.size > 0) {
                const eBookData = eBooks?.docs?.[0]?.data?.()

                return {
                    isbn: eBookData.isbn,
                    title: eBookData.title,
                    author: eBookData.author,
                    publish: eBookData.publish
                }
            }

            return false
        } catch(error) {
            Alert.error(error)

            return false
        }
    }

    static async deleteAllMyEbooks()
    {
        try
        {
            const ebooks = await Auth.user.ref.collection("ebooks").get()

            if(ebooks.size > 0)
            {
                const asyncProcessUpdateKeys   = [],
                      asyncProcessDeleteEbooks = []

                for(let ebook of ebooks.docs)
                {
                    asyncProcessUpdateKeys.push(new Promise(async (resolve, reject) =>
                    {
                        try
                        {
                            const ebookData = ebook.data(),
                                  key       = await ebookData.key.get()
                            
                            if(key.exists)
                            {
                                const keyData     = key.data(),
                                      newUserList = keyData.users.filter(userListRef => userListRef.id !== Auth.user.uid)
            
                                await key.ref.update({ users: newUserList })
                            }

                            resolve()
                        }
                        catch(error)
                        {
                            reject(error)
                        }
                    }))

                    asyncProcessDeleteEbooks.push(ebook.ref.delete())
                }

                await Promise.all(asyncProcessUpdateKeys)
                await Promise.all(asyncProcessDeleteEbooks)
            }

            return true
        }
        catch(error)
        {
            Alert.error(error)

            return false
        }
    }

    static async createEbook(isbn, title, author, frontCoverFile, epubFile) {
        try {
            if(Auth.user.permission >= 2) {
                if(!isbn || isbn.length < 13)
                    throw { code: "ebook/invalid-isbn" }
                else if(!title)
                    throw { code: "ebook/invalid-title" }
                else if(!author)
                    throw { code: "ebook/invalid-author" }
                else if(!Object.keys(frontCoverFile).length)
                    throw { code: "ebook/invalid-front-cover-file" }
                else if(!Object.keys(epubFile).length)
                    throw { code: "ebook/invalid-epub-file" }
    
                const ebooks = await Firebase.firestore().collection("ebooks").where("isbn", "==", parseInt(isbn)).get()
    
                if(ebooks.size > 0)
                    throw { code: "ebook/isbn-already-in-use" }
    
                if(frontCoverFile.type !== "image/png" && frontCoverFile.type !== "image/jpeg") 
                    throw { code: "file/invalid-file-type" } 

                if(epubFile.type !== "application/epub+zip") 
                    throw { code: "file/invalid-file-type" } 
    
                await Promise.all([
                    Firebase.uploadFile(frontCoverFile, `ebooks/${isbn}/front-cover`),
                    Firebase.uploadFile(frontCoverFile, `ebooks/${isbn}/front-cover.png`),
                    Firebase.uploadFile(epubFile, `ebooks/${isbn}/ebook.epub`),
                    Firebase.firestore().collection("ebooks").add(
                    {
                        isbn: parseInt(isbn),
                        title,
                        author,
                        publish: Firebase.firestore.FieldValue.serverTimestamp()
                    })
                ])
    
                return true
            }

            return false
        } catch(error) {
            Alert.error(error)

            return false
        }
    }

    static async updateEbook(isbn, title, author, frontCoverFile, epubFile) {
        try {
            if(Auth.user.permission >= 2) {
                const ebooks = await Firebase.firestore().collection("ebooks").where("isbn", "==", parseInt(isbn)).get()
        
                if(ebooks.size > 0) {
                    const ebook = ebooks.docs[0]
        
                    await ebook.ref.update({ title, author })
    
                    if(Object.keys(frontCoverFile).length) {
                        if(frontCoverFile.type !== "image/png" && frontCoverFile.type !== "image/jpeg") 
                            throw { code: "file/invalid-file-type" } 
                        
                        await Promise.all([
                            Firebase.uploadFile(frontCoverFile, `ebooks/${isbn}/front-cover`),
                            Firebase.uploadFile(frontCoverFile, `ebooks/${isbn}/front-cover.png`)
                        ])
                    }
    
                    if(Object.keys(epubFile).length) {
                        if(epubFile.type !== "application/epub+zip") 
                            throw { code: "file/invalid-file-type" } 
    
                        await Firebase.uploadFile(epubFile, `ebooks/${isbn}/ebook.epub`)
                    }
    
                    return true
                }
            }

            return false

        } catch(error) {
            Alert.error(error)

            return false
        }
    }

    static async deleteEbook(isbn) {
        try {
            if(Auth.user.permission >= 3) {
                const ebooks = await Firebase.firestore().collection("ebooks").where("isbn", "==", parseInt(isbn)).get()
    
                if(ebooks.size > 0) {
                    const ebook = ebooks.docs[0],
                          keys  = await ebook.ref.collection("keys").get()
    
                    if(keys.size > 0)
                        throw { code: "ebook/no-delete-with-keys-registered" }

                    await Promise.all([
                        ebook.ref.delete(),
                        Firebase.storage().ref(`ebooks/${isbn}/front-cover`).delete(),
                        Firebase.storage().ref(`ebooks/${isbn}/front-cover.png`).delete(),
                        Firebase.storage().ref(`ebooks/${isbn}/ebook.epub`).delete(),
                    ])

                    return true
                }
            }

            return false
        } catch(error) {
            Alert.error(error)

            return false
        }
    }
}