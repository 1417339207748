//firebase configurations
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'

//controllers
import Alert from './ControllerAlert'

export default class Firebase
{
    static get config()
    {
        return {
            apiKey: process.env.REACT_APP_API_KEY,
            authDomain: process.env.REACT_APP_AUTH_DOMAIN,
            projectId: process.env.REACT_APP_PROJECT_ID,
            storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
            messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
            appId: process.env.REACT_APP_APP_ID,
            measurementId: process.env.REACT_APP_MEASUREMENT_ID
        }
    }

    static get auth()
    {
        const firebaseAuth = firebase.auth

        if(!firebaseAuth)
            throw new Error("Não foi possível iniciar a autenticação com Firebase")

        return firebaseAuth
    }

    static get user()
    {
        return Firebase.auth().currentUser
    }

    static get firestore()
    {
        return firebase.firestore
    }

    static get storage()
    {
        return firebase.storage
    }

    static initializeApp()
    {
        firebase.initializeApp(Firebase.config)
    }

    /**
     * Faz upload de arquivos para o Firestore.
     * 
     * @example
     * ```js
     * await Firebase.uploadFile({name: "asads", url: "blob://..."}, "/posts") // https://firebasestorage.googleapis.com/.../youproject/file.png
     * ```
     * @param {string} file - Arquivo selecionado em um input file.
     * @param {string} firebasePath - Diretório do firebase que o arquivo será alocado ao efetuar o upload.
     * 
     * @returns {Promise<String | null>}
     */

    static async uploadFile(file, firebasePath, states) {
        try {
            const fileRef = Firebase.storage().ref(firebasePath),
                  uploadTask = fileRef.put(file)

            return await new Promise((resolve, reject) => {
                uploadTask.on("state_changed", snapshot => {
                    states?.stateChanged?.(snapshot)
                }, err => {
                    states?.error?.(err)
                    reject(err)
                }, () => {
                    states?.success?.()
                    resolve(true)
                })
            })
        } catch(error) {
            Alert.error(error)
            
            return false
        }
    }
}