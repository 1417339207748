// import * as AppleAuthentication from 'expo-apple-authentication'
// import * as Crypto from 'expo-crypto'
// import { GoogleSignin } from '@react-native-google-signin/google-signin'

import Firebase from './ControllerFirebase'
import Alert from './ControllerAlert'
import User from './ControllerUser'

//utils
import { validatePassword } from '../utils/validates'

export default class Auth
{
    static confirmation
    static _user

    static _firstStateLoaded = false

    static navigateList =
    {
        login: "/login",
        afterLogin: "/validation"
    }

    /**
     * Retorna a instância do usuário logado
     * 
     * @returns {User}
     */

    static get user()
    {
        return Auth._user
    }

    static get firstStateLoaded()
    {
        return Auth._firstStateLoaded
    }

    static set firstStateLoaded(value)
    {
        Auth._firstStateLoaded = !!value
    }

    /**
     * Verifica se foi feito a verificação de autenticação do início do app.
     * 
     * @example
     * ```js
     * await Auth.isFirstStateLoaded() // true ou false
     * ```
     * 
     * @returns {Promise<Boolean>}
     */

    static async isFirstStateLoaded()
    {
        try
        {
            await new Promise((resolve, reject) =>
            {
                let timeOut = 0

                const interval = setInterval(() =>
                {
                    if(Auth._firstStateLoaded)
                    {
                        resolve()
                        clearInterval(interval)
                    }
                    else
                        timeOut++

                    if(timeOut >= 5000)
                    {
                        clearInterval(interval)

                        reject({ message: "Usuário não pode ser carregado" })
                    }
                }, 0)
            })

            return true
        }
        catch(error)
        {
            Auth.error(error)

            await Auth.signOut()

            return false
        }
    }

    /**
     * Verifica se o usuário foi carregado, se não, a promessa força esperar até carrega-lo.
     * 
     * @example
     * ```js
     * await Auth.isLoadUser() // true ou false
     * ```
     * 
     * @returns {Promise<Boolean>}
     */

    static async isLoadUser()
    {
        try
        {
            await new Promise((resolve, reject) =>
            {
                let timeOut = 0

                const interval = setInterval(() =>
                {
                    if(Auth._firstStateLoaded && (!Auth._user || Auth._user.isLoadData))
                    {
                        resolve()
                        clearInterval(interval)
                    }
                    else
                        timeOut++

                    if(timeOut >= 5000)
                    {
                        clearInterval(interval)

                        reject({ message: "Usuário não pode ser carregado" })
                    }
                }, 0)
            })

            return true
        }
        catch(error)
        {
            Auth.error(error)

            await Auth.signOut()

            return false
        }
    }

    /**
     * Cria a instância do usuário e carrega seus dados caso exista, caso não, ele vai criar os dados padrão.
     * 
     * @example
     * ```js
     * await Auth.loadUser() // true ou false
     * ```
     * 
     * @returns {Promise<Boolean>} 
     */

    static async loadUser()
    {
        try
        {
            if(!!Firebase.user && !Auth._user)
            {
                Auth._user = new User()
                
                if(!await Auth._user.reload())
                    delete Auth._user
                else
                    return await Auth._user.getOrSetData()

                return false
            }
        }
        catch(error)
        {
            Auth.error(error)

            return false
        }
    }

    /**
     * Cadastrar um usuário com Email e senha.
     * 
     * @example
     * ```js
     * await Auth.createUserWithEmailAndPassword('meunome@email.com', 123456789, 123456789) // true ou false
     * ```
     * 
     * @param {String} email           - E-mail válido.
     * @param {Number} password        - Senha com no mínimo 6 caracteres.
     * @param {Number} confirmPassword - Confirmação da senha.
     * 
     * @returns {Promise<Boolean>}
     */

    static async createUserWithEmailAndPassword(email, password, confirmPassword)
    {
        try
        {
            if(!email)
                throw { code: "auth/insert-email" }
            else if(!password)
                throw { code: "auth/insert-password" }
            else if(!validatePassword(password))
                throw { code: "auth/wrong-password" }
            else if(!confirmPassword)
                throw { code: "auth/insert-comfirm-password" }
            else if(password !== confirmPassword)
                throw { code: "auth/passwords-not-match" }
            else
            {
                const userCredential = await Firebase.auth().createUserWithEmailAndPassword(email, password)

                if(!userCredential?.user?.emailVerified)
                {
                    await Auth.isLoadUser()

                    if(await Auth._user.sendEmailVerification())
                    {
                        await Auth.isLoadUser()

                        return await new Promise(resolve =>
                        {
                            Alert.success(
                                "Cadastro feito com sucesso",
                                "Enviamos um email de confirmação, por favor, confirme seu email para finalizar o cadastro",
                                () => resolve(true)
                            )
                        })
                    }
                }

                return !!userCredential
            }
        }
        catch(error)
        {
            Auth.error(error)

            return false
        }
    }

    /**
     * Efetuar o login com Email e senha
     * 
     * @example
     * ```js
     * await Auth.signInWithEmailAndPassword('meunome@email.com', 123456789) // true ou false
     * ```
     * 
     * @param {String} email    - E-mail.
     * @param {Number} password - Senha.
     * 
     * @returns {Promise<Boolean>}
     */

    static async signInWithEmailAndPassword(email, password)
    {
        try
        {
            if(!email)
                throw { code: "auth/insert-email" }
            else if(!password)
                throw { code: "auth/insert-password" }
            else
            {
                const userCredential = await Firebase.auth().signInWithEmailAndPassword(email, password)

                if(userCredential)
                    await Auth.isLoadUser()
                
                return !!userCredential
            }
        }
        catch(error)
        {
            Auth.error(error)

            return false
        }
    }

    /**
     * Efetuar o login com Google.
     * 
     * @example
     * ```js
     * await Auth.signInWithGoogle() // signIn
     * ```
     * 
     * @returns {Promise<Boolean>}
     */

    static async signInWithGoogle()
    {
        try
        {
            const provider = new Firebase.auth.GoogleAuthProvider()

            return await Firebase.auth().signInWithPopup(provider)
        }
        catch(error)
        {
            Auth.error({ message: `Não foi possivel efetuar o login com o Google, Tente novamente mais tarde.\n\nErro: ${error.message}` })

            return null
        }
    }

    /**
     * Efetuar o login com a Apple.
     * 
     * @example
     * ```js
     * await Auth.signInWithApple() // true ou false
     * ```
     * 
     * @returns {Promise<Boolean>}
     */

    // static async signInWithApple()
    // {
    //     try
    //     {
    //         const nonce           = Math.random().toString(36).substring(2, 10),
    //               hashedNonce     = await Crypto.digestStringAsync(Crypto.CryptoDigestAlgorithm.SHA256, nonce),
    //               appleCredential = await AppleAuthentication.signInAsync(
    //         {
    //             requestedScopes:
    //             [
    //                 AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
    //                 AppleAuthentication.AppleAuthenticationScope.EMAIL
    //             ],
    //             nonce: hashedNonce
    //         })

    //         const { identityToken } = appleCredential,
    //               credential        = Firebase.auth.AppleAuthProvider.credential(identityToken, nonce),
    //               signIn            = await Firebase.auth().signInWithCredential(credential)

    //         if(signIn)
    //             await Auth.isLoadUser()

    //         return true
    //     }
    //     catch(error)
    //     {
    //         Auth.error({ message: `Não foi possivel efetuar o login com a Apple, Tente novamente mais tarde.\n\nErro: ${error.message}` })

    //         return false
    //     }
    // }

    /**
     * Envia um link de redefinição de senha por e-mail.
     * 
     * @example
     * ```js
     * await Auth.sendPasswordResetEmail('meunome@email.com') // true ou false
     * ```
     * 
     * @param {String} email - E-mail.
     * 
     * @returns {Promise<Boolean>}
     */

    static async sendPasswordResetEmail(email)
    {
        try
        {
            if(!email)
                throw { code: "auth/insert-email" }

            const actionCodeSettings = {
                url: `${process.env.REACT_APP_REDIRECT_URL}/?email=${this.email}`,
                iOS: {
                    bundleId: process.env.REACT_APP_IOS_BUNDLE_IDENTIFIER
                },
                android: {
                    packageName: process.env.REACT_APP_ANDROID_PACKAGE,
                    installApp: true,
                    minimumVersion: "12"
                },
                handleCodeInApp: true,
                dynamicLinkDomain: process.env.REACT_APP_DYNAMIC_LINK
            }

            await Firebase.auth().sendPasswordResetEmail(email, actionCodeSettings)
            
            return true
        }
        catch(error)
        {
            Auth.error(error)

            return false
        }
    }

    /**
     * Desloga da conta.
     * 
     * @example
     * ```js
     * await Auth.signOut() // true ou false
     * ```
     * 
     * @returns {Promise<Boolean>}
     */

    static async signOut()
    {
        try
        {
            await Firebase.auth().signOut()

            delete Auth._user

            return true
        }
        catch(error)
        {
            return false
        }
    }

    /**
     * Efetua a configuração inicial da autênticação com o google.
     * 
     * @example
     * ```js
     * Auth.googleConfigure()
     * ```
     * 
     * @returns
     */

    // static googleConfigure()
    // {
    //     try
    //     {
    //         const googleService = require('../../google-services.json')

    //         if(!googleService)
    //             throw new Error("Não foi identificado o arquivo google-services.json")

    //         const clientsType3 = googleService.client[0].oauth_client.filter(client => client.client_type == 3),
    //               clientsType1 = googleService.client[0].oauth_client.filter(client => client.client_type == 1)

    //         if(!clientsType3.length || !clientsType3[0].client_id)
    //             throw new Error("Falha ao identificar o 'client_type 3' do arquivo google-services.json")
    //         else if(!clientsType1.length || !clientsType1[0].client_id)
    //             throw new Error("Falha ao identificar o 'client_type 1' do arquivo google-services.json")

    //         GoogleSignin.configure(
    //         {
    //             webClientId: clientsType3[0].client_id,
    //             androidClientId: clientsType1[0].client_id
    //         })
    //     }
    //     catch(error)
    //     {
    //         console.error(error)
    //     }
    // }

    /**
     * Efetua o redirecionamento automático quando efetua o login.
     * 
     * @example
     * ```js
     * await Auth.signInRedirect(navigation)
     * ```
     * 
     * @param {Navigation<react-navigation>} navigation - Objeto de navegação do react-navigation.
     * 
     * @returns
     */

    static async signInRedirect(navigate)
    {
        try
        {
            Firebase.auth().currentUser ? navigate(Auth.navigateList.afterLogin) : navigate(Auth.navigateList.login)
        }
        catch(error)
        {
            console.error(error)
        }
    }

    /**
     * Exibe uma mensagem de erro relacionado a autênticação.
     * 
     * @example
     * ```js
     * Auth.error({ message: "Falha no login" }, () =>
     * {
     *     //Clicou Ok
     * }, () =>
     * {
     *     //Clicou fora do log de erro
     * })
     * ```
     * 
     * @param {Error | Object { code, title, message }} error - Objeto de erro.
     * @param {Function} onPress   - Função "callback" indicativo do clique no "ok"
     * @param {Function} onDismiss - Função "callback" indicativo do clique fora do log
     * 
     * @returns
     */

    static error(error, onPress, onDismiss)
    {
        if(!(error instanceof Error))
        {
            if(!error?.title)
                error.title = "Falha na autenticação"
        }

        Alert.error(error, onPress, onDismiss)
    }
}