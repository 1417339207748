import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import LoginLogoImage from '../../assets/images/LoginLogoImage.svg'
import BannerForgotPassword from '../../assets/images/BannerForgotPassword.svg'
import ArrowIcon from '../../assets/images/ArrowIcon.svg'

import './styles.css'

//components
import { showLoadIndicator } from '../../components/LoadIndicator'
import Input from '../../components/Input' 

//controllers
import Auth from '../../controllers/ControllerAuth'
import Alert from '../../controllers/ControllerAlert'

export const ForgotPassword = () => {
    const [email, setEmail] = useState(""),
          navigate          = useNavigate()

    const forgotPasswordAction = e => {
        e.preventDefault()

        showLoadIndicator(async () =>
        {
            if(await Auth.sendPasswordResetEmail(email))
            {
                Alert.success("Sucesso", "Email enviado com sucesso.")
                navigate("/login", { email }) //verificar como enviar o email para a outra página
                setEmail("")
            }
            else
                Alert.alert("Erro", "Ocorreu um erro ao enviar email.")
        })
    }

    return (
        <div className="a-page-forgotPassword">
            <div className="linha">
                <div className="coluna-A">
                    <img className="LoginPageImage" src={BannerForgotPassword} alt="" />
                </div>
                <div className="coluna-B">
                    <div className="ForgotPassword-ArrowHeader">
                        <img className="ForgotPassword-ArrowIconImg" onClick={() => navigate("/login")} src={ArrowIcon} alt="" />
                        <p>Recuperar Conta</p>
                    </div>
                    <div className="ForgotPasswordInitial">
                        <form className="FormForgotPassword" onSubmit={forgotPasswordAction}>
                            <img className="LoginLogoImage" src={LoginLogoImage} alt="" />
                            <p className="text-ForgotPassword">Informe o seu E-mail para que possamos lhe enviar um e-mail de redefinição de senha</p>
                            <Input type="email" placeholder={"E-mail"} value={email} onChange={e => setEmail(e.target.value)} style={{ marginBottom: "24px", width:"100%"}} />
                            <button className="btn btn-solicitar">Solicitar</button>
                        </form>
                    </div>
                </div>
            </div>
            <div className="background-black"></div>
        </div>
    )
}

export default ForgotPassword
