import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import SmallLogoInitialPage from '../../assets/images/SmallLogoInitialPage.svg'
import ProfilePhoto from '../../assets/images/ProfilePhoto.png'
import InitialPageImage from '../../assets/images/InitialPageImage.svg'
import cartIconSvg from '../../assets/images/cart-icon.svg'
import FrontCover from '../../assets/images/FrontCover.jpg'
import Flag from '../../assets/images/Flag.svg'
import KeyIcon from '../../assets/images/KeyIcon.svg'
import LogoutIcon from '../../assets/images/LogoutIcon.svg'
import closeIconSvg from '../../assets/images/close-icon.svg'
import addNew from '../../assets/images/addNew.svg'
import seloGooglePlay from '../../assets/images/google-play-badge.png'
import seloAppStore from '../../assets/images/app-store-badge.png'
import arrowOptions from '../../assets/images/arrow-options.svg'
import buyCart from '../../assets/images/buyCart.svg'

import './styles.css'

//components
import { showLoadIndicator } from '../../components/LoadIndicator'
import ModalManageEbook from '../../components/ModalManageEbook'
import Input from '../../components/Input'

//controllers
import Auth from '../../controllers/ControllerAuth'
import EBook from '../../controllers/ControllerEBook'
import Alert from '../../controllers/ControllerAlert'

//utils
import { systemFilter } from '../../utils/systemFilter'
import { verifyRescueEbooks } from '../../utils/verifyRescueEbooks'

export const InitialPage = () =>
{
    const [user]                              = useState(Auth.user),
          [isOpen, setIsOpen]                 = useState(false),
          [myLibrary, setMyLibrary]           = useState([]),
          [filterLibrary, setFilterLibrary]   = useState([]),
          [isLoadLibrary, setIsLoadLibrary]   = useState(false),
          [closeDownload, setCloseDownload]   = useState(true),
          [showButton, setShowButton]         = useState({}),
          [isModalVisible, setIsModalVisible] = useState(false),
          [selectedEBook, setSelectedEBook]   = useState({}),
          [search, setSearch]                 = useState(""),
          navigate                            = useNavigate()

    useEffect(() =>
    {
        setCloseDownload(!!window?.sessionStorage?.getItem("closeDownloadStore"))

        showLoadIndicator(async () =>
        {
            setMyLibrary(await EBook.getMyEBooks())
            setIsLoadLibrary(true)
        })

        verifyRescueEbooks(async () => setMyLibrary(await EBook.getMyEBooks()))
        Auth.user.deleteInteraction()
    }, [navigate])

    useEffect(() =>
    {
        if (!isModalVisible)
        {
            setSelectedEBook({})
        }
    }, [isModalVisible])

    useEffect(() =>
    {
        if(search)
        {
            const filter = myLibrary.filter(data => systemFilter(search, data.title) || systemFilter(search, data.author))

            setFilterLibrary(filter)
        }
    }, [myLibrary, search])

    function closeDownloadStore()
    {
        setCloseDownload(true)
        window.sessionStorage.setItem("closeDownloadStore", true)
    }

    function signOut()
    {
        showLoadIndicator(async () =>
        {
            if(await Auth.signOut())
                navigate("/login")
        })
    }

    function toggleBookButtons(e, ebookId)
    {
        e?.stopPropagation?.()
        e?.preventDefault?.()
        
        setShowButton({ ...showButton, [ebookId]: !showButton[ebookId] })
        
    }

    function toggleShowModal(e, ebook)
    {
        if(ebook)
            setSelectedEBook(ebook)

        e?.stopPropagation?.()
        e?.preventDefault?.()

        setIsModalVisible(true)
    }

    function EbookList({ library }) {
        return (
            <>
                {library.map(ebook => {
                    return (
                        <div className="initial_box" key={ebook.id}>
                            <img src={ebook.frontCoverUrl || FrontCover} alt="" onClick={(e) => {
                            e?.stopPropagation?.()
                            navigate(`/ebookviewer/${ebook.id}`)
                        }} />
                            {
                                user.permission >= 2 && (
                                    <div className="initialpage-internal-buttons">
                                        <div className="circle" />
                                        <img className={`arrow-options ${showButton[ebook.id] ? "arrow-up" : ""}`} alt="Arrow" src={arrowOptions} onClick={(e) => toggleBookButtons(e, ebook.id)} />
                                        {
                                            showButton[ebook.id] && (
                                                <div className="book-buttons">
                                                    <div className="edit-button" onClick={(e) => toggleShowModal(e, ebook)} >
                                                        <p>Editar</p>
                                                    </div>
                                                    {
                                                        user.permission >= 3 && (
                                                            <div className="delete-button" onClick={e => {
                                                                e.preventDefault()

                                                                deleteEbook(ebook.isbn)
                                                            }}>
                                                                <p>Excluir</p>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            )
                                        }
                                    </div>
                                )
                            }
                            {
                                ebook?.storage?.percentage >= 0 &&
                                    <div className="flag">
                                        <p>{ebook.storage.percentage}%</p>
                                        <img src={Flag} alt="Flag" />
                                    </div>
                            }
                        </div>
                    )
                })}
            </>
        )
    }

    function deleteEbook(isbn) {
        Alert.alert(
            "Precisamos da sua atenção",
            "Tem certeza que deseja deletar este livro? Esta ação será irreversível!",
            () => {
                showLoadIndicator(async () => {
                    if(await EBook.deleteEbook(isbn)) {
                        setMyLibrary(await EBook.getMyEBooks())
                        Alert.success("Sucesso", "Livro deletado com sucesso!")
                    }
                })
            }
        )
    }

    return (
        <div className="main-page">
            <div className="linha-Initial">
                <div className="navbar-left">
                    <img className="SmallLogoInitial" src={SmallLogoInitialPage} alt="" />
                </div>
                <div className="initial_content-search">
                    <Input type="search" placeholder="Pesquisar" value={search} onChange={e => setSearch(e.target.value)} />
                </div>
                <div className="navbar-right">
                    <div className="user-info">
                        <p className="user-name">{user.displayName}</p>
                        <p className="user-email">{user.email}</p>
                    </div>
                    <img className="profile-picture" src={user.photoURL || ProfilePhoto} alt="" onClick={() => setIsOpen(!isOpen)} />
                    <div className="dropdown-content" style={{height: isOpen ? `${51 * (user.permission >= 2 ? 3 : 2)}px` : 0}}>
                        {
                            user.permission >= 2 &&
                                <div className="initialPage-btn-panel" onClick={(e) => toggleShowModal(e)}>
                                    <img className="addNew-icon" alt="Gear Icon" src={addNew} />
                                    <p>Adicionar eBook</p>
                                </div>
                        }
                        <div className="initialPage-btn-redefine-password" onClick={() => navigate("/changepassword")}>
                            <img className="keyIcon" alt="Key Icon" src={KeyIcon} />
                            <p>Redefinir Senha</p>
                        </div>
                        <div className="initialPage-btn-logout" onClick={signOut}>
                            <img className="logoutIcon" alt="Logout Icon" src={LogoutIcon} />
                            <p>Sair</p>
                        </div>

                    </div>
                </div>
            </div>
            <div className="initial_a-content">
                {
                    isLoadLibrary &&
                    <>
                        {
                            myLibrary.length <= 0
                            ?
                                <div className="initial_content-noHasBook">
                                    <div className="body-left">
                                        <img src={InitialPageImage} alt="" />
                                    </div>
                                    <div className="body-right">
                                        <div className="a-body-right-buy">
                                            <p>Adicione seu primeiro livro a plataforma clicando no carrinho.</p>
                                            <a href="https://www.spovo.com.br/podutos-digitais/e-book/" target="_blank" rel="noreferrer" className="btnShop">
                                                <img className="iconShop" src={cartIconSvg} alt="" />
                                                <p>Comprar</p>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            :
                                <div className="initial_content-hasBook">
                                    <h1>Biblioteca</h1>
                                    <div className="initial_a-container">
                                        <EbookList library={search ? filterLibrary : myLibrary} />
                                    </div>
                                    <div className="purchase__rounded">
                                        <a href="https://www.spovo.com.br/podutos-digitais/e-book/" target="_blank" rel="noreferrer">
                                            <img src={buyCart} alt="" />
                                        </a>
                                    </div>     
                                </div>
                        }
                    </>
                }
            </div>
            <div className="initial_a-information-download-app" style={{ bottom: !closeDownload ? 0 : undefined }}>
                <h5>Acesse os nossos aplicativos das lojas</h5>
                <div className="a-download">
                    <a href="https://play.google.com/store/apps/details?id=com.deviigd.gracadigital" target="_blank" rel="noreferrer" className="googlePlay">
                        <img src={seloGooglePlay} alt="" />
                    </a>
                    <a href="https://apps.apple.com/br/app/gra%C3%A7a-digital/id1668867796?l=en" target="_blank" rel="noreferrer" className="playStore">
                        <img src={seloAppStore} alt="" />
                    </a>
                </div>
                <div className="information-download-close" onClick={closeDownloadStore}>
                    <img src={closeIconSvg} alt="" />
                </div>
            </div>
            {
                isModalVisible && 
                    <ModalManageEbook selectedEBook={selectedEBook} setIsVisible={setIsModalVisible} reloadEbooks={async () => {
                        setMyLibrary(await EBook.getMyEBooks())
                        setShowButton({})
                    }} />
            }
        </div>
    )
}

export default InitialPage; 
