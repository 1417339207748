import Firebase from '../controllers/ControllerFirebase'
import Auth from '../controllers/ControllerAuth'

export default function TriggerAuth() {
    Firebase.auth().onAuthStateChanged(async user =>
    {
        await Auth.loadUser()
    
        if(!Auth.firstStateLoaded)
            Auth.firstStateLoaded = true
    })
}