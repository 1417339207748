import { useState, useEffect } from 'react'
import { useLocation, Navigate } from 'react-router-dom'

import DownloadApp from '../../pages/DownloadApp'
//controllers
import Auth from '../../controllers/ControllerAuth'

export default function ProtectedRoute({ Element, loginRequired, logoutRequired, permissionRequired}) {
    const [width, setWidth] = useState(window.innerWidth)
    const location = useLocation()

    function handleWindowSizeChange() {
        setWidth(window.innerWidth)
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange)

        return () => {
            window.removeEventListener('resize', handleWindowSizeChange)
        }
    }, [])

    if(!["validation", "changepassword", "register", "forgotpassword", "login", "rescue", "privacypolicy"].find(url => location.pathname.indexOf(`/${url}`) !== -1) && width <= 768)
        return <DownloadApp />

    if(loginRequired && location.pathname !== "/validation" && (!Auth?.user?.displayName || !(Auth?.user?.email && Auth?.user?.emailVerified)))
        return <Navigate to="/validation" />

    if(loginRequired && permissionRequired && Auth?.user?.permission < permissionRequired)
        return <Navigate to="/" />

    if(loginRequired && !!!Auth.user)
        return <Navigate to="/login" />
    else if(logoutRequired && !!Auth.user)
        return <Navigate to="/" />

    return <Element />
}