import { useEffect, useCallback, useState } from 'react'
import ePub from 'epubjs'
import { useParams, useNavigate } from 'react-router-dom'

import ArrowIcon from '../../assets/images/ArrowIcon.svg'

import './styles.css'

//components
import { showLoadIndicator } from '../../components/LoadIndicator'

//controllers
import EBook from '../../controllers/ControllerEBook'
import Alert from '../../controllers/ControllerAlert'
import Auth from '../../controllers/ControllerAuth'

const EbookViewer = () =>
{
    const [ebookElement, setEbookElement] = useState(),
          { EBookId } = useParams(),
          navigate    = useNavigate()

    const verifyAccessFromEBook = useCallback(async () => {
        const { time, deviceName } = await Auth.user.getLastInteraction(),
        hasAccessFromEBook   = !time ? true : time <= Date.now()

        if(!hasAccessFromEBook && Auth.user.permission <= 0) {
            Alert.success(
                "Atenção",
                `Você atingiu o limite de leituras simultâneas.\nVocê já possui o livro aberto ${deviceName ? `no dispositivo ${deviceName}` : `em outro dispositivo`}.\nFeche o livro para continuar lendo.`,
                () => navigate("/")
            )

            Auth.user.deleteInteraction()
        }

        return hasAccessFromEBook
    }, [navigate])

    const renderEbook = useCallback((url, pageCfi = "") =>
    {
        try
        {
            if(!url)
                throw new Error("A variavel url precisa ser preenchida")
            
            showLoadIndicator(true)

            const book      = ePub(url),
                  rendition = book.renderTo("ebook",
            {
                width: "100%",
                height: "calc(100vh - 40px)",
                spread: "always",
                allowScriptedContent: true,
            })

            setEbookElement(book)
        
            const displayed = rendition.display(pageCfi || undefined)
        
            book.ready.then(async () =>
            {
                Auth.user.updateUserInteraction()
                verifyAccessFromEBook().then(() => showLoadIndicator(false))

                rendition.on("rendered", () =>
                {
                    let hideInformationTimeout

                    const eBookDocument = rendition.getContents()[0].document

                    eBookDocument.addEventListener("selectionchange", element =>
                    {
                        const textSelected  = eBookDocument.getSelection().toString(),
                                countSelected = textSelected.length

                        if(countSelected > 1300)
                            eBookDocument.getSelection().removeAllRanges()
                    })

                    eBookDocument.addEventListener("copy", element => element.preventDefault())
                    eBookDocument.querySelectorAll("p, h1, h2, h3, h4, h5").forEach(e => e.addEventListener("dragstart", element => element.preventDefault()))
                    eBookDocument.addEventListener("click", element =>
                    {
                        if(element.target.tagName.toLowerCase() !== "a" || !element.target.getAttribute("href"))
                        {
                            const areaTotalWidth = eBookDocument.querySelector("html").clientWidth,
                                pageTotal      = rendition.location.start.displayed.total,
                                areaView       = areaTotalWidth / pageTotal,
                                clickX         = element.clientX,
                                currentPage    = Math.ceil(clickX / areaView),
                                safeAreaClick  = { start: areaView * (currentPage - 1), end: areaView * currentPage },
                                areaClick      = areaView / 3
        
                            if(clickX <= (safeAreaClick.start + areaClick))
                                book.package.metadata.direction === "rtl" ? rendition.next() : rendition.prev()
                            else if(clickX >= (safeAreaClick.end - areaClick))
                                book.package.metadata.direction === "rtl" ? rendition.prev() : rendition.next()
                        }
        
                        if(hideInformationTimeout)
                            clearTimeout(hideInformationTimeout)
        
                        if(document.querySelector(".page-total").innerHTML !== "") {
                            const elementInformationPage = document.querySelector(".a-information-page")
            
                            elementInformationPage.style.bottom = 0
            
                            hideInformationTimeout = setTimeout(() =>
                            {
                                elementInformationPage.style.bottom = "-45px"
            
                                hideInformationTimeout = null
                            }, 3 * 1000)
                        }
                    })
                })
        
                await book.locations.generate(1300)
        
                rendition.on("relocated", location =>
                {
                    const percent    = book.locations.percentageFromCfi(location.end.cfi),
                          percentage = Math.floor(percent * 100)
        
                    document.querySelector(".current-page").innerHTML = book.locations.locationFromCfi(location.end.cfi)
                    
                    Auth.user.updateUserInteraction()
                    verifyAccessFromEBook()
                    window?.localStorage?.setItem(EBookId.toString(), JSON.stringify({ pageCfi: location.start.cfi, percentage }))
                })

                displayed.then(() =>
                {
                    if(document.querySelector(".a-information-page")) {
                        document.querySelector(".current-page").innerHTML = book.locations.locationFromCfi(rendition.currentLocation()?.end?.cfi)
                        document.querySelector(".page-total").innerHTML = book.locations.total
                        document.querySelector(".a-information-page").style.opacity = 1
                    }
                })
            })
        }
        catch(error)
        {
            console.error(error)
            showLoadIndicator(false)
        }
    }, [EBookId, verifyAccessFromEBook])

    const getFileUrl = useCallback(async () => {
        showLoadIndicator(true)
            
        const eBookUrl = await EBook.getFileUrlEBookById(EBookId)

        if(await verifyAccessFromEBook()) {
            if(eBookUrl)
                renderEbook(eBookUrl)
            else
                navigate("/")
        }
        
        showLoadIndicator(false)
    }, [EBookId, navigate, renderEbook, verifyAccessFromEBook])

    useEffect(() =>
    {
        getFileUrl()
    }, [getFileUrl])

    useEffect(() => {
        return () => ebookElement?.destroy?.()
    }, [ebookElement])

    return (
        <div className="a-Ebook">
            <div className="ebook_a-back-action">
                <div className="ebook_back">
                    <div className="ebook_back-action" onClick={() => {
                        Auth.user.deleteInteraction()
                        navigate('/')
                    }}>
                        <div className="ebook_back-button">
                            <img src={ArrowIcon} alt="" />
                        </div>
                        <p>Fechar livro</p>
                    </div>
                </div>
            </div>
            <div className="a-book">
                <div id="ebook"></div>
            </div>
            <div className="a-information-page">
                <p><span className="current-page"></span> / <span className="page-total"></span></p>
            </div>
        </div>
    )
}

export default EbookViewer